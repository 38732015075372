<template>
  <div>
    <div v-if="pageReady">
      <form class="vue-form px-5">
        <fieldset>
          <h1 class="mb-5 text-dark">
            <strong>Patient Consultation Details</strong>
          </h1>

          <div class="my-3">
            <p class="mb-3 d-inline">
              Does the patient have any history of other compensable event?
            </p>
            <div class="float-end">
              <BaseRadioGroup
                v-model="hasEvent"
                :options="defaultOptions"
                :label="`hasEvent`"
              />
            </div>
          </div>

          <div v-if="hasEvent" class="my-3">
            <textarea
              v-model="consultation.compensable_history"
              placeholder="please enter the detail..."
              class="form-control"
              rows="3"
            />
          </div>

          <div class="my-5">
            <label class="form-check-label">
              Does the patient has any relevant radiology image(s) ?
            </label>
            <div class="float-end">
              <BaseRadioGroup
                v-model="consultation.has_relevant_radiology_images"
                :options="defaultOptions"
                :label="`RelevantRadiologyImage`"
              />
            </div>

            <textarea
              v-if="consultation.has_relevant_radiology_images"
              v-model="consultation.radiology_images"
              :disabled="!consultation.has_relevant_radiology_images"
              :placeholder="
                consultation.has_relevant_radiology_images
                  ? 'please enter the detail of the imagings...'
                  : 'not applicable...'
              "
              class="form-control my-3"
              rows="3"
            />
          </div>

          <div class="my-5">
            <label class="form-check-label">
              Does the patient have any history of related injury prior to the
              referred injury, or progress prior to reffered injury?
            </label>
            <div class="form-check form-check-inline my-3">
              <label for="injury" class="text-gray-600">
                The patient did not have any other significantly relevant prior
                injury
              </label>
              <input
                id="injury"
                v-model="consultation.has_preinjury_history"
                class="form-check-input"
                type="checkbox"
              />
            </div>
            <textarea
              v-model="consultation.preinjury_history"
              :disabled="consultation.has_preinjury_history"
              placeholder="please enter the detail...."
              class="form-control"
              rows="3"
            />
          </div>

          <div class="my-3">
            <p class="mb-3 d-inline">
              Was the patient cooperative during the assessment?
            </p>
            <div class="float-end">
              <BaseRadioGroup
                v-model="consultation.did_patient_cooperate"
                :options="defaultOptions"
              />
            </div>

            <textarea
              v-if="!consultation.did_patient_cooperate"
              v-model="consultation.non_cooperative_reason"
              :disabled="consultation.did_patient_cooperate"
              class="form-control"
              placeholder="please enter reason of why patient was not cooperative..."
              rows="2"
            />
          </div>

          <div class="container-fluid mt-5 d-flex justify-content-center">
            <BaseBtn :class="`my-3`" @click="submit">Save</BaseBtn>
          </div>
        </fieldset>
      </form>
    </div>
    <div
      v-else
      class="min-vh-100 d-flex justify-content-center align-items-center"
    >
      <div>
        <div
          class="spinner-border text-primary"
          style="width: 12rem; height: 12rem"
          role="status"
        >
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseRadioGroup from '@/components/Base/BaseRadioGroup.vue'
import BaseBtn from '@/components/Base/BaseButton.vue'

export default {
  name: 'DoctorForm',
  components: { BaseRadioGroup, BaseBtn },
  props: {
    assessmentId: { type: Number, default: null },
    readyNext: { type: Boolean },
    oldAssessmentData: {
      type: Object,
      default: null,
    },
  },

  emits: ['update:readyNext'],

  data() {
    return {
      consultation: {
        compensable_history: '',
        did_patient_cooperate: true,
        has_preinjury_history: true,
        has_relevant_radiology_images: false,
        non_cooperative_reason: '',
        preinjury_history: '',
      },

      defaultOptions: [
        {
          value: true,
          label: 'Yes',
        },
        {
          value: false,
          label: 'No',
        },
      ],
      hasNoHistory: true,
      hasEvent: false,
      pageReady: false,
    }
  },

  mounted() {
    //map existing data
    this.consultation = this.oldAssessmentData.patient.consultation

    this.pageReady = true
  },

  methods: {
    async submit() {
      let value = {
        patient: {
          consultation: this.consultation,
        },
      }

      this.linkToAssessment(value)

      let buttonEnable = true
      this.$emit('update:readyNext', buttonEnable)
    },

    async linkToAssessment(obj) {
      let payload = {}
      payload.id = this.assessmentId
      payload.payload = obj

      await this.$store
        .dispatch('patchAssessment', payload)
        .then(() => {
          // Trigger toast text
          this.$root.toasts.push({
            status: 'success',
            content: `Successfully linked doctor's consultation to report.`,
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>

<style lang="scss">
.vue-form {
  font-size: 16px;

  padding: 15px 30px;
  border-radius: 4px;
  margin: 50px auto;

  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.3);

  h1 {
    font-size: 24px;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
  border-bottom: 1px solid #ecf0f1;
}

.vue-form h4,
.vue-form .label {
  color: #94aab0;
  margin-bottom: 10px;
}
.vue-form .label {
  display: block;
}

.vue-form input:focus,
.vue-form textarea:focus,
.vue-form select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 5px rgba(44, 151, 222, 0.2);
}
</style>
