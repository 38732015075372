<template>
  <span class="text-danger">*</span>
</template>

<script>
// TOOD: To work on moving inputs to this instead
export default {
  name: 'BaseRequiredLabel',
}
</script>
